import React from "react";
import "./NotificationBarComponent.css";
import Warning_icon from "../../../../assets/icons/Warning.svg";
import User_filled_icon from "../../../../assets/icons/UserIconFilled.svg";
import close_icon from "../../../../assets/icons/Close-icon-noborder.svg";

const NotificationBarComponent = ({
  classNames,
  closeErrorBar,
  errorMessage,
  isError,
  isFixed = false,
  show,
}) => {
  return show == undefined || show ? (
    <>
      <div
        className={
          (isError ? "blatant-warning" : "blatant-success") +
          (isFixed ? " position-fixed" : "")
        }
      >
        <img
          className="warning-icon-blatant"
          src={isError ? Warning_icon : User_filled_icon}
          alt="Warning_icon"
        />
        <span className="blatant-warning-text">{errorMessage}</span>
        <img
          className={`ErrorCloseIcon ${classNames}`}
          src={close_icon}
          alt="close_icon"
          onClick={closeErrorBar}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

export default NotificationBarComponent;
