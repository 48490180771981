import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import Addobject from "../../../assets/icons/Plus-manage-shows.svg";
import Button from "../../shared/UI/Button/Button";
import Search_icon from "../../../assets/icons/search-icon.svg";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import * as Constants from "../../shared/Constants";
import * as actions from "../../../store/actions/index";
import "./ManageShow.css";
import ErrorMessage from "../../shared/ErrorMessage";
import Backdrop from "../../shared/UI/Backdrop/Backdrop";
import moment from "moment";
import { timeInHours } from "../../shared/utility";
import showLogo from "../../../assets/icons/Show.svg";
import Select from "react-select";
import { ReactComponent as Plus_icon } from "../../../assets/icons/add-icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Trash.svg";
import Warning_icon from "../../../assets/icons/Warning.svg";
import CloseIconComponent from "../../shared/UI/CloseIconComponent/CloseIconComponent";
import invertedArrow from "../../../utils/dropdownArrow/dropdownArrow";
import NotificationBarComponent from "../../shared/UI/NotificationBarComponent/NotificationBarComponent";
import { Input } from "antd";
import { ReactComponent as SearchInputIcon } from "../../../assets/icons/search-input-icon.svg";

const ManageShow = (props) => {
  const {
    showList,
    networkList,
    onFetchNetworkList,
    token,
    onSaveShow,
    onSelectedShow,
    showData,
    onDeleteShow,
    onUpdateShow,
    onResetShowData,
    currentUser,
    facilityList,
  } = props;

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const [showName, setShowName] = useState(showData.showName);
  const [slateUrl, setSlateUrl] = useState(showData.slateImageUrl);
  const [networkId, setNetworkId] = useState(
    showData.networkId ? showData.networkId : 1
  );
  const [facilityId, setFacilityId] = useState(
    showData.facilityId ? showData.facilityId : 1
  );
  const [startTime, setStartTime] = useState(showData.startTime);
  const [endTime, setEndTime] = useState(showData.endTime);
  const [occursSun, setOccursSun] = useState(showData.occursSun);
  const [occursMon, setOccursMon] = useState(showData.occursMon);
  const [occursTue, setOccursTue] = useState(showData.occursTue);
  const [occursWed, setOccursWed] = useState(showData.occursWed);
  const [occursThu, setOccursThu] = useState(showData.occursThu);
  const [occursFri, setOccursFri] = useState(showData.occursFri);
  const [occursSat, setOccursSat] = useState(showData.setOccursSat);
  const [description, setDescription] = useState(showData.description);
  const [createdBy] = useState(
    showData.createdBy ? showData.createdBy : userId
  );
  const [showModalError, setShowModalError] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [selectedFacility, setSelectedFacility] = useState("");
  const [showDropdownIsOpen, setShowOpenDropdown] = useState(false);
  const [facilityDropdownIsOpen, setFacilityOpenDropdown] = useState(false);
  const [showWarningInfo, setShowWarningInfo] = useState(false);
  const [WarningCloseIcon, setWarningCloseIcon] = useState(false);
  const [warningNo, setWarningNo] = useState(false);
  const [warningConform, setWarningConform] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorBar, setShowErrorBar] = useState(false);

  const onUpdateSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const results = showList.filter((list) =>
    list.showName.toLowerCase().includes(searchTerm.toLocaleLowerCase())
  );

  const onCloseTimePicker = (type) => {
    document.querySelector(".rc-time-picker-panel").style.display = "none";
    document
      .querySelector(
        (type == "start" ? ".manageshowstarttime" : ".manageshowendtime") +
          " .rc-time-picker-input"
      )
      .focus();
  };

  const onOccurrenceSun = () => {
    setOccursSun(!occursSun);
  };
  const onOccurrenceMon = () => {
    setOccursMon(!occursMon);
  };
  const onOccurrenceTue = () => {
    setOccursTue(!occursTue);
  };
  const onOccurrenceWed = () => {
    setOccursWed(!occursWed);
  };
  const onOccurrenceThu = () => {
    setOccursThu(!occursThu);
  };
  const onOccurrenceFri = () => {
    setOccursFri(!occursFri);
  };
  const onOccurrenceSat = () => {
    setOccursSat(!occursSat);
  };

  const validateShowData = (showData = {}) => {
    const validationFields = [
      { key: "showName", message: "Please enter the name" },
      { key: "startTime", message: "Please enter the start time" },
      { key: "endTime", message: "Please enter the end time" },
      { key: "networkId", message: "Please select the Network" },
      { key: "facilityId", message: "Please select the facility" },
      { key: "description", message: "Please enter the description" },
    ];

    let errorMsg = null;
    const hasEmptyField = validationFields.some(({ key, message }) => {
      errorMsg = message;
      return !showData[key] && showData[key] !== 0;
    });
    if (hasEmptyField) {
      return errorMsg;
    }

    if (showData.startTime === showData.endTime) {
      showData.endTime = undefined;
      return "Start time and end time can't be same";
    }
    const startTimeFormat = timeInHours(showData.startTime);
    const endTimeFormat = timeInHours(showData.endTime);
    if (startTimeFormat > endTimeFormat) {
      return "End time can't be less than start time";
    }

    return;
  };

  useEffect(() => {
    onFetchNetworkList(token);
  }, [onFetchNetworkList, token]);

  useEffect(() => {
    setShowName(showData.showName);
    setSlateUrl(showData.slateImageUrl);
    if (showData.networkId === undefined) {
      setNetworkId(1);
      if (networkList.length > 0) {
        setSelectedNetwork({
          value: networkList[0].networkId,
          key: networkList[0].networkId,
          label: networkList[0].networkName,
        });
      }
    } else {
      setNetworkId(showData.networkId);
    }
    if (showData.facilityId === undefined) {
      setFacilityId(1);
      if (facilityList.length > 0) {
        setSelectedFacility({
          value: facilityList[0].facilityId,
          key: facilityList[0].facilityId,
          label: facilityList[0].facilityName,
        });
      }
    } else {
      setFacilityId(showData.facilityId);
    }
    setStartTime(showData.startTime);
    setEndTime(showData.endTime);
    setOccursSun(showData.occursSun);
    setOccursMon(showData.occursMon);
    setOccursTue(showData.occursTue);
    setOccursWed(showData.occursWed);
    setOccursThu(showData.occursThu);
    setOccursFri(showData.occursFri);
    setOccursSat(showData.occursSat);
    setDescription(showData.description);
    if (showData.networkId !== null) {
      const networkListvalues = networkList.map((option) => ({
        value: option.networkId,
        key: option.networkId,
        label: option.networkName,
      }));
      networkListvalues.forEach((st) => {
        if (st.value === showData.networkId) {
          handleSelectedNetwork(st);
        }
      });
    }
    if (showData.facilityId !== null) {
      const facilityListvalues = facilityList.map((option) => ({
        value: option.facilityId,
        key: option.facilityId,
        label: option.facilityName,
      }));
      facilityListvalues.forEach((st) => {
        if (st.value === showData.facilityId) {
          handleSelectedFacility(st);
        }
      });
    }
  }, [showData]);

  const closeModal = () => {
    setShowModalError(false);
  };

  const openErrorBar = (message, isAbsError) => {
    setErrorMessage(message);
    setIsError(isAbsError);
    setShowErrorBar(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const closeErrorBar = () => {
    setErrorMessage("");
    setShowErrorBar(false);
  };

  let showError = null;
  if (showModalError) {
    showError = (
      <Fragment>
        <Backdrop show={showModalError} clicked={closeModal} />
        <ErrorMessage closeModal={closeModal} message={showMessage} />
      </Fragment>
    );
  }

  const onShowSubmit = (e) => {
    e.preventDefault();
    const showFormData = {};
    showFormData.showName = showName;
    showFormData.slateImageUrl = slateUrl;
    showFormData.networkId = networkId;
    showFormData.startTime = startTime;
    showFormData.endTime = endTime;
    showFormData.occursSun = occursSun;
    showFormData.occursMon = occursMon;
    showFormData.occursTue = occursTue;
    showFormData.occursWed = occursWed;
    showFormData.occursThu = occursThu;
    showFormData.occursFri = occursFri;
    showFormData.occursSat = occursSat;
    showFormData.description = description;
    showFormData.createdBy = createdBy;
    showFormData.facilityId = facilityId;
    const hasErrorMsg = validateShowData(showFormData);
    if (hasErrorMsg) {
      openErrorBar(hasErrorMsg, true);
      return false;
    }
    if (showData.showId !== undefined) {
      showFormData.showId = showData.showId;
      showFormData.updatedBy = userId;
      showFormData.createdBy = userId;
      onUpdateShow(showFormData, token);
    } else {
      onSaveShow(showFormData, token);
      onNewShow();
    }
  };

  const onBackClicked = () => {
    onResetShowData();
    props.modalClosed();
  };

  const onShowStartTimeChange = (value) => {
    const Time = value && value.format(Constants.FORMAT_TIME);
    setStartTime(Time);
  };

  const onShowEndTimeChange = (value) => {
    const Time = value && value.format(Constants.FORMAT_TIME);
    setEndTime(Time);
  };
  const onNewShow = () => {
    onResetShowData();
    showData.showId = undefined;
    setShowName("");
    setDescription("");
    setNetworkId(1);
    setFacilityId(1);
    setOccursMon(false);
    setOccursTue(false);
    setOccursWed(false);
    setOccursThu(false);
    setOccursFri(false);
    setOccursSat(false);
    setOccursSun(false);
    setStartTime(undefined);
    setEndTime(undefined);
    setSelectedNetwork("");
    setSelectedFacility("");
    closeErrorBar();
  };
  const onSelectedShowData = (e) => {
    e.preventDefault();
    onSelectedShow(token, e.target.value);
  };
  const closeWarningInfo = () => {
    setShowWarningInfo(false);
    setWarningCloseIcon(true);
  };
  const cancelWarningInfo = () => {
    setShowWarningInfo(false);
    setWarningNo(true);
  };

  const onDeleteShowById = () => {
    setShowWarningInfo(true);
    setWarningNo(false);
    setWarningConform(false);
  };

  const showDelete = () => {
    onDeleteShow(token, showData.showId);
    setShowWarningInfo(false);
    setWarningConform(true);
  };

  const handleSelectedNetwork = (chosenValue) => {
    setSelectedNetwork({
      value: chosenValue.value,
      key: chosenValue.key,
      label: chosenValue.label,
    });
    setNetworkId(chosenValue.value);
  };

  const handleSelectedFacility = (chosenValue) => {
    setSelectedFacility({
      value: chosenValue.value,
      key: chosenValue.key,
      label: chosenValue.label,
    });
    setFacilityId(chosenValue.value);
  };

  return (
    <div className="manage-shows-container">
      {showErrorBar && (
        <NotificationBarComponent
          errorMessage={errorMessage}
          closeErrorBar={closeErrorBar}
          isError={isError}
        ></NotificationBarComponent>
      )}
      <form onSubmit={onShowSubmit}>
        <div className="manage-shows-header-row">
          <img
            src={showLogo}
            alt="manage shows logo"
            className="manage-shows-logo"
          />
          <label className="manage-shows-title">Manage Shows</label>
          <CloseIconComponent onCloseModal={props.modalClosed} />
        </div>
        <div className="manage-shows-block">
          <div className="searchmodal">
            <div className="manage-show-search">
              <Input
                placeholder="SHOW NAME"
                className={`common-search-input ${searchTerm ? "filled" : ""}`}
                prefix={<SearchInputIcon className="search-input-icon" />}
                value={searchTerm || ""}
                onChange={onUpdateSearch}
              />
            </div>

            <div className="manage-showlist">
              <ul onClick={onSelectedShowData}>
                {" "}
                {results.map((showlist) => (
                  <li
                    key={showlist.showId}
                    value={showlist.showId}
                    className={"manage-showlist-li"}
                  >
                    {showlist.showName}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="manageshowdetails">
            <div className="manage-left">
              <div>
                <label>
                  <span className="redAsterisk">*</span>Show Name:
                </label>
                <input
                  type="text"
                  className="manageshowname manageshowdetails-input"
                  placeholder="SHOW NAME"
                  value={showName || ""}
                  onChange={(e) => setShowName(e.target.value)}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </div>

              <div className="manage-time-row">
                <div className="manage-time-boxes">
                  <label>
                    <span className="redAsterisk">*</span>Start Time:
                  </label>
                  <TimePicker
                    placeholder="Select Time"
                    showSecond={false}
                    format={Constants.FORMAT_TIME}
                    className="manageshowstarttime"
                    onChange={onShowStartTimeChange}
                    minuteStep={5}
                    use12Hours
                    allowEmpty={true}
                    value={startTime ? moment(startTime, "hh:mm A") : undefined}
                    onAmPmChange={() => onCloseTimePicker("start")}
                  />
                </div>
                <div className="manage-time-boxes">
                  <label>
                    <span className="redAsterisk">*</span>End Time:
                  </label>
                  <TimePicker
                    showSecond={false}
                    format={Constants.FORMAT_TIME}
                    className="manageshowendtime"
                    onChange={onShowEndTimeChange}
                    minuteStep={5}
                    use12Hours
                    placeholder="SELECT TIME"
                    allowEmpty={true}
                    value={endTime ? moment(endTime, "hh:mm A") : undefined}
                    onAmPmChange={() => onCloseTimePicker("end")}
                  />
                </div>
              </div>
              <div>
                <label>Slate URL:</label>
                <input
                  type="text"
                  className="slateurl manageshowdetails-input"
                  placeholder="ENTER SLATE URL"
                  value={slateUrl || ""}
                  onChange={(e) => setSlateUrl(e.target.value)}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </div>

              <div>
                <label>
                  <span className="redAsterisk">*</span>Description:
                </label>
                <textarea
                  className="manageshowdescp"
                  value={description || ""}
                  rows="4"
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="ENTER DESCRIPTION"
                />
              </div>
            </div>

            <div className="manage-right">
              <div className="manage-right-row">
                <div className="network_manageshows">
                  <label>
                    <span className="redAsterisk">*</span>Network:{" "}
                  </label>
                  <div>
                    {
                      <Select
                        className={
                          showDropdownIsOpen
                            ? "networkdropdown green-bg black-text"
                            : selectedNetwork
                            ? "networkdropdown  white-text grey-bg"
                            : selectedNetwork && showDropdownIsOpen
                            ? "networkdropdown  green-bg black-text"
                            : "networkdropdown  grey-text grey-bg"
                        }
                        classNamePrefix="manage-shows-dropdown"
                        onMenuOpen={() => setShowOpenDropdown(true)}
                        onMenuClose={() => setShowOpenDropdown(false)}
                        styles={showDropdownIsOpen ? invertedArrow : ""}
                        onChange={handleSelectedNetwork}
                        value={selectedNetwork}
                        cache={{
                          "": [
                            { label: "SELECT", value: null, disabled: true },
                          ],
                        }}
                        options={networkList.map((option) => ({
                          value: option.networkId,
                          key: option.networkId,
                          label: option.networkName,
                        }))}
                      />
                    }
                  </div>
                </div>

                <div className="facility_manageshows">
                  <label>
                    <span className="redAsterisk">*</span>Facility:
                  </label>
                  <div>
                    <Select
                      className={
                        facilityDropdownIsOpen
                          ? "facilitydropdown green-bg black-text"
                          : selectedFacility
                          ? "facilitydropdown  white-text grey-bg"
                          : selectedFacility && facilityDropdownIsOpen
                          ? "facilitydropdown  green-bg black-text"
                          : "facilitydropdown  grey-text grey-bg"
                      }
                      classNamePrefix="manage-facilities-dropdown"
                      onMenuOpen={() => setFacilityOpenDropdown(true)}
                      onMenuClose={() => setFacilityOpenDropdown(false)}
                      styles={facilityDropdownIsOpen ? invertedArrow : ""}
                      onChange={handleSelectedFacility}
                      value={selectedFacility}
                      cache={{
                        "": [{ label: "SELECT", value: null, disabled: true }],
                      }}
                      options={facilityList.map((option) => ({
                        value: option.facilityId,
                        key: option.facilityId,
                        label: option.facilityName,
                      }))}
                    />
                  </div>
                </div>
              </div>
              <div className="occurrence-box">
                <label>Occurrence:</label>
                <div className="manageshowoccurrence">
                  <Button
                    type="button"
                    btnType={
                      occursSun ? "weekendday sun toggleWeek" : "weekendday sun"
                    }
                    onClick={onOccurrenceSun}
                  >
                    SUN
                  </Button>
                  <Button
                    type="button"
                    btnType={
                      occursMon ? "weekday mon toggleWeek" : "weekday mon"
                    }
                    onClick={onOccurrenceMon}
                  >
                    MON
                  </Button>
                  <Button
                    type="button"
                    btnType={
                      occursTue ? "weekday tue toggleWeek" : "weekday tue"
                    }
                    onClick={onOccurrenceTue}
                  >
                    TUE
                  </Button>
                  <Button
                    type="button"
                    btnType={
                      occursWed ? "weekday wed toggleWeek" : "weekday wed"
                    }
                    onClick={onOccurrenceWed}
                  >
                    WED
                  </Button>
                  <Button
                    type="button"
                    btnType={
                      occursThu ? "weekday thur toggleWeek" : "weekday thur"
                    }
                    onClick={onOccurrenceThu}
                  >
                    THU
                  </Button>
                  <Button
                    type="button"
                    btnType={
                      occursFri ? "weekday fri toggleWeek" : "weekday fri"
                    }
                    onClick={onOccurrenceFri}
                  >
                    FRI
                  </Button>
                  <Button
                    type="button"
                    btnType={
                      occursSat ? "weekendday sat toggleWeek" : "weekendday sat"
                    }
                    onClick={onOccurrenceSat}
                  >
                    SAT
                  </Button>
                </div>
              </div>
              <div>
                <label>Slate Thumbnail:</label>
                <div className="description-img-container">
                  {slateUrl ? (
                    <div className="description-img-innerdiv">
                      <img
                        src={slateUrl}
                        className="description-img"
                        alt="Slate Thumbnail"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {showWarningInfo && (
            <div className="popup-modal  trashInfo-container">
              <div className="warning-icon-content">
                <img
                  className="warning-icon"
                  src={Warning_icon}
                  alt="Warning_icon"
                />
                <span className="warning-headline">Warning</span>
                <CloseIconComponent
                  onCloseModal={closeWarningInfo}
                  classNames={
                    WarningCloseIcon
                      ? "press-warning-Close-icon"
                      : "warning-Close-icon"
                  }
                />
              </div>
              <div className="warning-content">
                You are about to delete a Show. Are you sure you want to
                continue this action?
              </div>
              <div className="warning-buttons">
                <button
                  className={
                    warningNo
                      ? "press-warning-cancel-btn"
                      : "warning-cancel-btn"
                  }
                  onClick={cancelWarningInfo}
                >
                  NO
                </button>
                <button
                  className={
                    warningConform
                      ? "press-warning-cancel-btn"
                      : "warning-submit-btn"
                  }
                  onClick={() => showDelete()}
                >
                  YES
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="manage-shows-buttons">
          <div className="manage-shows-buttons-left">
            <Button type="button" btnType="newshow" onClick={onNewShow}>
              <Plus_icon alt="add icon" className="add-plus-icon" />
              <span>CREATE NEW SHOW</span>
            </Button>
            <Button
              type="button"
              btnType="deleteshow"
              onClick={onDeleteShowById}
              disabled={showData.showId === undefined ? true : false}
            >
              <TrashIcon className="manage-trash-icon" />
              <span>DELETE SHOW</span>
            </Button>
          </div>

          <div className="manage-shows-buttons-right">
            <Button
              type="button"
              btnType="manageshowback"
              onClick={onBackClicked}
            >
              CANCEL
            </Button>
            <Button type="submit" btnType="manageshowsave">
              SAVE
            </Button>
          </div>
        </div>
      </form>
      {showError}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showList: state.showInfo.showList,
    networkList: state.showInfo.networkList,
    token: state.auth.accessToken,
    showData: state.showInfo.showData,
    currentUser: state.auth.currentUser,
    facilityList: state.showInfo.facilityList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetShowData: () => dispatch(actions.resetShowData()),
    onFetchShowList: (token) => dispatch(actions.fetchShowList(token)),
    onFetchNetworkList: (token) => dispatch(actions.fetchNetworkList(token)),
    onSaveShow: (showFormData, token) =>
      dispatch(actions.saveShow(showFormData, token)),
    onUpdateShow: (showFormData, token) =>
      dispatch(actions.updateShow(showFormData, token)),
    onSelectedShow: (token, Id) => dispatch(actions.fetchShowData(token, Id)),
    onDeleteShow: (token, Id) => dispatch(actions.deleteShow(token, Id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageShow);
