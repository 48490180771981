import * as actionTypes from "./actionTypes";
import * as actions from "../../store/actions/index";
import axios from "../../axios";
import { currentDate } from "../../components/shared/utility";
import moment from "moment";
import { format } from "date-fns";

export const addCurrentCanvas = (id, canvas) => {
  return {
    type: actionTypes.CURRENT_CANVAS,
    currentCanvas: canvas,
    currentCanvasId: id,
  };
};

export const addCapabilityType = (
  routingCapabilityType,
  commCapabilityType
) => {
  return {
    type: actionTypes.ADD_CAPABILITY,
    routingCapabilityType: routingCapabilityType,
    commCapabilityType: commCapabilityType,
  };
};

export const fetchCanvasListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_CANVAS_LIST_SUCCESS,
    canvasList: lists,
  };
};

export const fetchAbsPreferenceSuccess = (lists) => {
  return {
    type: actionTypes.SET_ABS_LS_PREFERENCE,
    preference: lists,
  };
};

export const fetchControlRoomListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_CONTROLROOM_LIST_SUCCESS,
    controlRoomList: lists,
  };
};

export const canvasCheckSuccess = (msg) => {
  return {
    type: actionTypes.CANVAS_CHECK_SUCCESS,
    canvasMsg: msg,
    showCreateCanvas: true,
  };
};

export const closeCreateCanvasModal = () => {
  return {
    type: actionTypes.CANVAS_CHECK_SUCCESS,
    showCreateCanvas: false,
  };
};

export const seletedShowDate = (showdate) => {
  return {
    type: actionTypes.SHOW_DATE,
    showDate: showdate,
  };
};

export const checkNullShowDate = (nullShowExist) => {
  return {
    type: actionTypes.NULL_SHOWDATE_CHECK,
    nullShowExist: nullShowExist,
  };
};

export const addLiveshotFromTemplateOrDate = () => {
  return {
    type: actionTypes.LIVESHOT_ADDED_FROM_TEMPLATE_OR_DATE,
  };
};

export const resetAddedFromTemplateOrDate = () => {
  return {
    type: actionTypes.RESET_ADDED_FROM_TEMPLATE_OR_DATE,
  };
};

export const fetchCurrentCanvas = (token, showId, userId) => {
  return (dispatch) => {
    let date = currentDate();
    axios
      .get(`liveshot-api/canvas/readCanvasByDate/${showId}?date=${date}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedCanvas = res.data;
        if (res.status === 200 && fetchedCanvas && fetchedCanvas.canvasId) {
          dispatch(addCurrentCanvas(fetchedCanvas.canvasId, fetchedCanvas));
          dispatch(
            actions.getHostStudioLiveShots(fetchedCanvas.canvasId, token)
          );
        } else {
          const today = date;
          const canvasInfo = {};
          canvasInfo.showId = showId;
          canvasInfo.roomId = sessionStorage.getItem("defaultRoom");
          canvasInfo.showDate = today;
          canvasInfo.createdBy = userId;
          canvasInfo.updatedBy = userId;
          canvasInfo.isRoomSelected = true;
          canvasInfo.softDelete = false;
          dispatch(createCanvas(token, canvasInfo));
        }
        dispatch(resetAddedFromTemplateOrDate());
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log("error", err.response);
      });
  };
};

export const createCanvas = (token, canvasInfo) => {
  return (dispatch) => {
    axios
      .post(`liveshot-api/canvas/`, canvasInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(closeCreateCanvasModal());
        dispatch(setCurrentCanvas(token, res.data));
      })
      .catch((err) => {
        console.log("error ", err);
        dispatch(closeCreateCanvasModal());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(actions.loadingEnd());
      });
  };
};

export const setCurrentCanvas = (token, canvasInfo) => {
  return (dispatch) => {
    dispatch(actions.getHostStudioLiveShots(canvasInfo.canvasId, token));
    dispatch(addCurrentCanvas(canvasInfo.canvasId, canvasInfo));
    dispatch(actions.resetAddedFromSearch());
  };
};

export const fetchCanvasList = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/canvas/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedCanvasList = [];
        for (let key in res.data) {
          fetchedCanvasList.push({
            ...res.data[key],
          });
        }
        dispatch(fetchCanvasListSuccess(fetchedCanvasList));
      })
      .catch((err) => {
        dispatch(actions.noLiveShots());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchAllLiveshotPreference = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/abstractliveshotpreference?type=json", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedList = [];
        for (let key in res.data) {
          fetchedList.push({
            ...res.data[key],
          });
        }
        dispatch(fetchAbsPreferenceSuccess(fetchedList));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchControlRoomList = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/room/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedControlRoomList = [];
        for (let key in res.data) {
          fetchedControlRoomList.push({
            ...res.data[key],
          });
        }
        sessionStorage.setItem("defaultRoom", fetchedControlRoomList[0].roomId);
        dispatch(fetchControlRoomListSuccess(fetchedControlRoomList));
      })
      .catch((err) => {
        //dispatch(actions.noLiveShots());
        console.log(err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const canvasCheck = (token, date, showId, userId, showLoader) => {
  return (dispatch) => {
    showLoader && dispatch(actions.loadingStart());
    axios
      .get(`liveshot-api/canvas/readCanvasByDate/${showId}?date=${date}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 202) {
          dispatch(canvasCheckSuccess("Select a Control Room: "));
          const canvasInfo = {};
          canvasInfo.showId = showId;
          canvasInfo.roomId = sessionStorage.getItem("defaultRoom");
          canvasInfo.showDate = date;
          canvasInfo.createdBy = userId;
          canvasInfo.updatedBy = userId;
          canvasInfo.isRoomSelected = true;
          canvasInfo.softDelete = false;
          dispatch(createCanvas(token, canvasInfo));
          dispatch(resetAddedFromTemplateOrDate());
        } else {
          dispatch(setCurrentCanvas(token, res.data));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log(err.status);
        showLoader && dispatch(actions.loadingEnd());
      });
  };
};

export const fetchCanvasTemplate = (token, showId, userId) => {
  return (dispatch) => {
    dispatch(actions.loadingStart());
    axios
      .get(`liveshot-api/canvas/readCanvasByDate/${showId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        let canvasTemplate = res.status === 200 && res.data;
        console.log(res.data);
        console.log(canvasTemplate);

        if (canvasTemplate) {
          dispatch(setCurrentCanvas(token, canvasTemplate));
        } else {
          // create new template canvas
          const canvasInfo = {};
          canvasInfo.showId = showId;
          canvasInfo.roomId = sessionStorage.getItem("defaultRoom");
          canvasInfo.showDate = undefined;
          canvasInfo.createdBy = userId;
          canvasInfo.updatedBy = userId;
          canvasInfo.isRoomSelected = true;
          canvasInfo.softDelete = false;
          canvasInfo.isDefault = true;
          dispatch(createCanvas(token, canvasInfo));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(actions.loadingEnd());
        console.log(err.status);
      });
  };
};

export const updateCanvas = (payload, token, roomChange) => {
  return (dispatch) => {
    axios
      .put("liveshot-api/canvas/", payload, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (roomChange) {
          dispatch(addCurrentCanvas(res.data.canvasId, res.data));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log("err ", err);
      });
  };
};

export const processCopyCanvas = (
  token,
  showId,
  sourceDate = "",
  destinationDate,
  sso
) => {
  return (dispatch) => {
    let copyCanvasUrl = `liveshot-api/canvas/copy?showId=${showId}&sourceDate=${sourceDate}&destinationDate=${destinationDate}&createdBy=${sso}&force=true`;
    axios
      .post(copyCanvasUrl, null, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(canvasCheck(token, destinationDate, showId));
        dispatch(addLiveshotFromTemplateOrDate());
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(
          actions.showCommonPopupModal({
            showModal: true,
            title: "Error",
            content: `Canvas ${
              sourceDate ? "" : " Template "
            } does not exist to copy.\n`,
            type: "error",
          })
        );
      });
  };
};

export const saveSelectedShowDate = (showdate) => {
  return (dispatch) => {
    dispatch(seletedShowDate(showdate));
  };
};

export const downloadToExcel = (token, showId, showDate, details) => {
  return (dispatch) => {
    dispatch(actions.toggleLoader());
    let downloadUrl = null;
    let currentTime =
      format(new Date(), "yyyy-MM-dd") + " " + moment().format("HH:mm:ss");
    if (showDate !== null) {
      downloadUrl = `liveshot-api/liveshot/download?type=xlsx&showId=${showId}&date=${showDate}&details=${details}&printDate=${currentTime}`;
    } else {
      downloadUrl = `liveshot-api/liveshot/download?type=xlsx&showId=${showId}&details=${details}`;
    }
    axios
      .get(downloadUrl, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        responseType: "blob",
      })
      .then((res) => {
        dispatch(actions.toggleLoader());
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        dispatch(actions.toggleLoader());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log(err.status);
      });
  };
};

export const checkCapabilityType = (token, room) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/room/${room.coreId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(
          addCapabilityType(
            res.data.routingCapabilityType,
            res.data.commCapabilityType
          )
        );
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };
};

export const settingArmRouting = (isArmRouting) => {
  return {
    type: actionTypes.ARM_ROUTING,
    armRouting: isArmRouting,
  };
};

export const keyShortcut = () => {
  return {
    type: actionTypes.KEY_SHORT_CUT,
  };
};

export const deviceStreamDeck = () => {
  return {
    type: actionTypes.DEVICE_STREAM_DECK,
  };
};

export const setActiveLiveshot = (id) => {
  return {
    type: actionTypes.SET_ACTIVE_LIVESHOT,
    liveshotId: id,
  };
};

export const setNextActiveLiveshot = (id) => {
  return {
    type: actionTypes.SET_NEXT_ACTIVE_LIVESHOT,
    liveshotId: id,
  };
};

export const setPressedKey = (key) => {
  return {
    type: actionTypes.SET_PRESSED_KEY,
    key: key,
  };
};
