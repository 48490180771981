import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { ReactComponent as HostIcon } from "../../../assets/icons/host-icon.svg";
import { ReactComponent as StudioIcon } from "../../../assets/icons/studio-icon.svg";
import { ReactComponent as GuestIcon } from "../../../assets/icons/guest-icon.svg";
import { ReactComponent as ReporterIcon } from "../../../assets/icons/reporter-icon.svg";
import { ReactComponent as EventIcon } from "../../../assets/icons/event-icon.svg";
import { ReactComponent as OtherIcon } from "../../../assets/icons/other-icon.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play-icon.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user-icon.svg";
import Button from "../../../components/shared/UI/Button/Button";
import Tooltip from "../../../components/shared/UI/Tooltip/Tooltip";
import "./Searchitem.css";
import moment from "moment";
import infoLogo from "../../../assets/icons/Info.svg";
import addSearchIcon from "../../../assets/icons/Plus-searchliveshot.svg";
import { getColValue } from "../../../components/shared/utility";
import { useUser } from "../../../hooks/useUser";
import { ReactComponent as ExtendPermIcon } from "../../../assets/icons/extend-perm-icon.svg";
import Label from "../../../components/shared/UI/LabelledCheckbox/Label";

const SearchItem = (props) => {
  const { createLiveshotPerm } = useUser();
  const {
    blockId,
    userList,
    currentCanvasId,
    onSaveCurrentLiveShot,
    searchInfo,
    searchTermValue,
    onFetchSelectedSignal,
    liveShotSignalSelected,
    currentUserRole,
    currentUser,
    newliveshot,
    onCreateAbstractLiveshot,
    onSaveCitySate,
    openErrorBar,
    closeErrorBar,
    isError,
    // studioTruckList,
    token,
    room,
    facilityId,
  } = props;

  const [expDate, setExpDate] = useState("");
  const [record, setRecord] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [inBoundPaths, setInBoundPaths] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorBar, setShowErrorBar] = useState(false);
  const [tempVType, setTempVType] = useState(
    "E.G. 30R LOCATION, SKYPE, QUICKLINK..."
  );
  const timeInUtc = moment().utc().format("MM/DD/YYYY HH:mm");

  useEffect(() => {
    // Check if searchTermValue exists, and if abstract live shot name exists
    if (searchTermValue && searchInfo.length === 0) {
      if (searchTermValue.length > 0) {
        searchInfo.abstractLiveshotName = searchTermValue.toUpperCase();
      }
    }
    let newDate;
    if (searchInfo && searchInfo.expiration) {
      let newDate = new Date(searchInfo.expiration).toLocaleDateString();
      setExpDate(newDate);
    }
  }, [searchInfo]);

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const onAddLiveShot = (e, isNewAbsLiveshot) => {
    const currentCanvasValue = currentCanvasId;
    const absLiveshotData = {};
    const searchObj = {};
    const searchData = {
      abstractLiveshot: {},
    };

    //If the abstract liveshot exists in search/add, add abstract to canvas
    if (
      !isNewAbsLiveshot &&
      searchData.abstractLiveshot.abstractLiveshotId !== undefined
    ) {
      searchData.blockId = blockId;
      searchData.expiration = searchInfo.expiration;
      searchData.permanentLiveshot = searchInfo.permanentLiveshot;
      searchData.createdBy = parseInt(userId);
      searchData.updatedBy = parseInt(userId);
      searchData.abstractLiveshot.abstractLiveshotId = 0;
      searchData.abstractLiveshot.liveshotTypeId = searchInfo.liveshotTypeId;
      searchData.hitNote = "";
      searchData.abstractLiveshot.hitNote = "";
      searchData.abstractLiveshot.abstractLiveshotName =
        searchInfo.abstractLiveshotName.toUpperCase();
      searchData.venueType = searchInfo.venueType;
      searchData.abstractLiveshot.location = searchInfo.location;
      searchData.abstractLiveshot.facilityId = facilityId;
      searchData.abstractLiveshot.createdBy = searchInfo.createdBy;
      searchData.abstractLiveshot.updatedBy = searchInfo.updatedBy;
      const cityStateInfo = {};
      cityStateInfo.location = searchInfo.location;
      cityStateInfo.createdBy = parseInt(userId);
      cityStateInfo.updatedBy = parseInt(userId);
      onSaveCurrentLiveShot(currentCanvasId, searchData, token, room);
    }
    //Condition if the abastract liveshot ID is undefined, but exists on the search/add modal. Adds to canvas
    else if (
      !isNewAbsLiveshot &&
      searchData.abstractLiveshot.abstractLiveshotId === undefined
    ) {
      const fromSearch = true;
      searchData.hitNote = null;
      searchData.blockId = blockId;
      searchData.expiration = searchInfo.expiration;
      searchData.permanentLiveshot = searchInfo.permanentLiveshot;
      searchData.hitTime = null;
      searchData.needsMediaTrafficReview = false;
      searchData.readyForAir = false;
      searchData.hitNote = "";
      searchData.abstractLiveshot.hitNote = "";
      searchData.record = false;
      searchData.sequenceNo = 0;
      searchData.canceled = false;
      searchData.venueType = searchInfo.venueType;
      searchData.canceledBy = 0;
      searchData.canceledOn = null;
      searchData.createdBy = parseInt(userId);
      searchData.updatedBy = parseInt(userId);
      searchData.softDelete = false;
      searchData.abstractLiveshot.abstractLiveshotId =
        searchInfo.abstractLiveshotId;
      searchData.fromSearch = fromSearch;
      searchData.abstractLiveshot.facilityId = facilityId;
      onSaveCurrentLiveShot(currentCanvasValue, searchData, token, null);
    }

    // If abstract liveshot does not exist yet, create new abstract liveshot + add that to canvas
    else if (
      isNewAbsLiveshot &&
      searchData.abstractLiveshot.abstractLiveshotId === undefined
    ) {
      const searchDataAbs = {
        abstractLiveshot: {},
      };
      searchDataAbs.abstractLiveshot.liveshotTypeId = 1;
      searchDataAbs.abstractLiveshot.abstractLiveshotId = 0;
      searchDataAbs.abstractLiveshot.abstractLiveshotName =
        searchInfo.abstractLiveshotName.toUpperCase();
      searchDataAbs.abstractLiveshot.venueType = tempVType;
      searchDataAbs.venueType = tempVType;
      searchDataAbs.sequenceNo = 0;
      searchDataAbs.hitNote = "";
      searchDataAbs.abstractLiveshot.hitNote = "";
      searchDataAbs.fromSearch = true;
      searchDataAbs.softDelete = false;
      searchDataAbs.abstractLiveshot.location = "";
      searchData.expiration = null;
      searchDataAbs.abstractLiveshot.facilityId = facilityId;
      searchDataAbs.abstractLiveshot.readOnly = false;
      searchDataAbs.blockId = blockId;
      searchDataAbs.createdBy = parseInt(userId);
      searchDataAbs.updatedBy = parseInt(userId);
      searchData.fromSearch = true;
      onSaveCurrentLiveShot(currentCanvasValue, searchDataAbs, token, null);
    }
    setTimeout(() => {
      props.clicked();
    }, 500);
  };

  let icon = null;
  let liveshotType = null;
  switch (searchInfo.liveshotTypeId) {
    case 1:
      icon = <HostIcon />;
      liveshotType = "Local Host";
      break;
    case 2:
      icon = <StudioIcon />;
      liveshotType = "Beauty Shot";
      break;
    case 3:
      icon = <GuestIcon />;
      liveshotType = "Local Guest";
      break;
    case 4:
      icon = <HostIcon />;
      liveshotType = "Remote Host";
      break;
    case 5:
      icon = <GuestIcon />;
      liveshotType = "Remote Guest";
      break;
    case 6:
      icon = <ReporterIcon />;
      liveshotType = "Remote Reporter";
      break;
    case 7:
      icon = <EventIcon />;
      liveshotType = "Remote Event";
      break;
    case 9:
      icon = <PlayIcon className="playback" />;
      liveshotType = "Edit/Playback";
      break;
    case 8:
      icon = <OtherIcon />;
      liveshotType = "Other";
      break;
    default:
      icon = null;
  }
  // let studioList = null;
  // if (studioTruckList.length > 0) {
  //   studioList = studioTruckList.find((stList) => stList.stId === searchInfo.stId);
  // }

  let user = null;
  if (userList.length > 0) {
    user = userList.find((usr) => usr.sso === searchInfo.updatedBy);
  }
  const updateInfo = (
    <div className="update-lsinfo-content">
      <div className="update-info-liveshotId">
        <span>Abstract Live Shot ID: </span>
        {searchInfo ? searchInfo.abstractLiveshotId : ""}
      </div>
      <div className="update-info-updated-by">
        <span>Updated By: </span>
        {user ? user.userName : "Unknown"}
      </div>
      <div className="update-info-updated-on">
        <span>Updated On:</span>{" "}
        {searchInfo && searchInfo.updatedOn
          ? new Date(searchInfo.updatedOn).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: true,
            })
          : ""}
      </div>
    </div>
  );
  const recordToggle = () => {
    if (!isDisable) setRecord(!record);
  };

  const lastUsedInfo =
    searchInfo && searchInfo.lastUsed
      ? new Date(searchInfo.lastUsed).toLocaleString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        })
      : "";

  let paths = inBoundPaths;
  let recording = record ? (
    <div
      onClick={recordToggle}
      className="record"
      aria-hidden="true"
      title="Select to indicate recording needed"
    >
      <div className="recording"></div>
    </div>
  ) : (
    <div
      onClick={recordToggle}
      className="record"
      aria-hidden="true"
      title="Select to indicate recording needed"
    ></div>
  );
  return (
    <div className="search-item container">
      <div className="search-upper-row">
        <div className="talent-venue-title">
          {searchInfo.abstractLiveshotName ? (
            <div className="talent-name">{searchInfo.abstractLiveshotName}</div>
          ) : (
            <div className="talent-name">{searchTermValue}</div>
          )}
          <div className="venue-name">{searchInfo.venue}</div>
        </div>
        <Tooltip
          message={updateInfo}
          position="searchls-info-position"
          title="Update Info"
          messageTheme="searchls-info-container"
          titleTheme="lssearch-info"
        >
          <img className="search-info-icon" src={infoLogo} alt="info" />
        </Tooltip>
      </div>
      <div className="search-middle-row">
        <div className="search-time-box col-border col-border-starttime">
          <label className="label-text">Start Time</label>
          {searchInfo.abstractLiveshotName ? (
            searchInfo.windowStartTime ? (
              <input
                className="searchbox-input "
                value={searchInfo.windowStartTime}
                disabled={true}
              />
            ) : (
              <>
                <div className="search-placeholder">
                  <div>HH:MM</div> <div>AM/PM</div>
                </div>
              </>
            )
          ) : null}
        </div>
        <div className="search-time-box col-border col-border-endtime">
          <label className="label-text">End Time</label>
          {searchInfo.abstractLiveshotName ? (
            searchInfo.windowEndTime ? (
              <input
                className="searchbox-input"
                value={searchInfo.windowEndTime}
                disabled={true}
              />
            ) : (
              <>
                <div className="search-placeholder">
                  <div>HH:MM</div> <div>AM/PM</div>
                </div>
              </>
            )
          ) : null}
        </div>
        <div className="search-time-box col-border col-border-hittime">
          <label className="label-text">Hit Time</label>
          {searchInfo.abstractLiveshotName ? (
            searchInfo.windowHitTime ? (
              <input
                className="searchbox-input"
                value={searchInfo.windowHitTime}
                disabled={true}
              />
            ) : (
              <>
                <div className="search-placeholder">
                  <div>HH:MM</div> <div>AM/PM</div>
                </div>
              </>
            )
          ) : null}
        </div>
        <div className="search-venue-box col-border">
          <label className="label-text">Contact</label>
          {searchInfo.abstractLiveshotName ? (
            <>
              <input
                className="searchbox-input search-time-placeholder"
                value={searchInfo.studioContactName}
                disabled={true}
                placeholder="NAME"
              />
              <input
                className="searchbox-input search-time-placeholder"
                value={searchInfo.studioContactPhone}
                disabled={true}
                placeholder="PHONE"
              />
            </>
          ) : null}
        </div>
        <div className="search-lstype-box col-border liveshot-ion search-tx-box">
          <div className="input-group">
            <label className="label-text">Tx</label>
            {searchInfo.abstractLiveshotName ? (
              <div className="col-content cursor">
                {getColValue(searchInfo, "inOutTxs")}
              </div>
            ) : null}
          </div>
        </div>
        <div className="search-lstype-box col-border col-border-pl">
          <div className="input-group">
            <label className="label-text">IFB </label>
            <div className="col-content cursor">
              {getColValue(searchInfo, "crPathNamesCommsIFB", true)}
            </div>
          </div>
        </div>
        <div className="search-lstype-box col-border col-border-pl">
          <div className="input-group">
            <label className="label-text">PL </label>
            <div className="col-content cursor">
              {getColValue(searchInfo, "crPathNamesCommsPL", true)}
            </div>
          </div>
        </div>
        <div className="search-lstype-box col-border col-border-pho">
          <div className="input-group">
            <label className="label-text">Phoner </label>
            <div className="col-content cursor">
              {getColValue(searchInfo, "crPathNamesCommsPhoner", true)}
            </div>
          </div>
        </div>
        <div className="form-group-global col-border global-box">
          <div className="comms-canvas-right">
            <div className="input-group">
              <label className="label-text">Global</label>
              <div className="col-content cursor">
                {getColValue(searchInfo, "inboundGlobalPaths")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="search-lower-row">
        <div style={{ width: "71px" }}>
          <Label
            icon={UserIcon}
            isActive={searchInfo.hostEnabled}
            labelText="HOST"
            disableHover={true}
          />
        </div>
        <div className="search-expDate">
          {searchInfo.permanentLiveshot ? (
            <span className="exp-input-overlay">
              <ExtendPermIcon
                title="Extend Permanetly Icon"
                className="extend-perm-icon-overlay"
              />
            </span>
          ) : (
            <span className="exp-input-date">
              EXP: {searchInfo.abstractLiveshotName ? expDate : "MM/DD/YYYY"}
            </span>
          )}
        </div>
        <div className="reference-info-count ">
          <span className="count-label">Use Count:</span>
          <input
            className="use-count"
            value={searchInfo.ref || "--"}
            disabled={true}
          />
        </div>

        <div className="reference-info-count" disabled={true}>
          <div className="last-used-label">
            <span className="count-label">Last Used:</span>
          </div>
          <div
            className={"last-used-time"}
            title={lastUsedInfo}
            disabled={true}
          >
            {searchInfo.lastUsed !== undefined
              ? moment(searchInfo.lastUsed).fromNow()
              : searchInfo.abstractLiveshotName
              ? "--"
              : moment(searchInfo.lastUsed).fromNow().toLowerCase() ===
                "in a few seconds"
              ? "A FEW SECONDS AGO"
              : moment(searchInfo.lastUsed).fromNow().toLowerCase() ===
                "in a hour"
              ? "AN HOUR AGO"
              : moment(searchInfo.lastUsed).fromNow().toLowerCase() ===
                "in a minute"
              ? "A MINUTE AGO"
              : "--"}
          </div>
        </div>

        {searchInfo.abstractLiveshotName ? (
          <Button
            onClick={(e) => onAddLiveShot(e, false)}
            btnType="add-ls"
            className="addicon"
            disabled={!createLiveshotPerm}
          >
            <img src={addSearchIcon} alt="add icon" />
            ADD
          </Button>
        ) : (
          <Button
            onClick={(e) => onAddLiveShot(e, true)}
            btnType="add-ls-new"
            className="addicon"
            disabled={!createLiveshotPerm}
          >
            <img src={addSearchIcon} alt="add icon" />
            ADD NEW LIVE SHOT
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCanvasId: state.canvasInfo.currentCanvasId,
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    userList: state.user.userList,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,

    // studioTruckList: state.studioTruckInfo.studioTruckList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveCurrentLiveShot: (currentCanvasValue, formData, token, room) =>
      dispatch(
        actions.saveCurrentLiveShot(currentCanvasValue, formData, token, room)
      ),
    onSaveCitySate: (cityStateInfo, token) =>
      dispatch(actions.saveCityState(cityStateInfo, token)),
    onFetchSelectedSignal: (token, liveshotId, canvasId) =>
      dispatch(actions.fetchSelectedSignal(token, liveshotId, canvasId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchItem);
