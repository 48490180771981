import React, { useEffect } from "react";
import "./Backdrop.css";

const Backdrop = (props) => {
  useEffect(() => {
    // Disable root container scrolling when modal is open
    const rootElm = document.getElementById("root");
    if (props.show) {
      rootElm.style.overflow = "hidden";
    } else {
      rootElm.style.overflow = "initial";
    }

    return () => {
      rootElm.style.overflow = "initial";
    };
  }, [props.show]);

  return props.show ? (
    <div className="Backdrop" onClick={props.clicked}></div>
  ) : null;
};

export default Backdrop;
