export const NOTREADY_FOR_AIR = "NOT READY FOR AIR";
export const NOTREADY = "NOT READY";
export const READY_FOR_AIR = "READY FOR AIR";
export const READY_FOR_MEDIA_TRAFFIC_REVIEW = "READY FOR MTC";
export const READY_FOR_CONTROL_ROOM = "READY FOR PCR";
export const CANCELED = "CANCELED";
export const CLEAR = "CLEAR";
export const FORMAT_TIME = "hh:mm A";
export const LIVESHOT_SAVE_SUCCESS_MSG = "Liveshot data saved successfully";
export const LIVESHOT_SAVE_FAILURE_MSG = "Unable to save LiveShot data";

export const LIVESHOT_ADD_SUCCESS_MSG = "Liveshot added successfully";
export const LIVESHOT_ADD_FAILURE_MSG = "Unable to add Liveshot";

export const LIVESHOT_UPDATE_SUCCESS_MSG = "Liveshot updated successfully";
export const LIVESHOT_UPDATE_FAILURE_MSG = "Unable to update LiveShot";

export const SHOW_SAVE_SUCCESS_MSG = "Show data saved successfully";
export const SHOW_SAVE_FAILURE_MSG = "Unable to save Show";
export const SHOW_UPDATE_SUCCESS_MSG = "Show updated successfully";
export const SHOW_UPDATE_FAILURE_MSG = "Unable to update Show";
export const DELETE_SHOW_SUCCESS_MSG = "Show deleted successfully";
export const DELETE_SHOW_FAILURE_MSG = "Unable to delete show"; //05:45 AM after 12:00 AM next day's 24hours added

/* export const EARLY_START_TIME = "04:14";
export const EARLY_END_TIME = "07:45";
export const MORNING_START_TIME = "06:14";
export const MORNING_END_TIME = "12:45";
export const AFTERNOON_START_TIME = "11:14";
export const AFTERNOON_END_TIME = "17:45";
export const PRIME_START_TIME = "18:14";
export const PRIME_END_TIME = "22:15";
export const NIGHT_START_TIME = "21:44";
export const NIGHT_END_TIME = "29:45"; */
export const SHOW_TIME_DETAILS = {
  MORNING_START_TIME: "03:45",
  MORNING_END_TIME: "12:15",
  DAYTIME_START_TIME: "11:45",
  DAYTIME_END_TIME: "19:15",
  PRIMETIME_START_TIME: "18:45",
  PRIMETIME_END_TIME: "24:15",
  OVERNIGHT_START_TIME: "23:45",
  OVERNIGHT_END_TIME: "03:45",
};

export const STUDIOTRUCK_SAVE_SUCCESS_MSG = "Studio data saved successfully";
export const STUDIOTRUCK_SAVE_FAILURE_MSG = "Unable to save Studio";
export const STUDIOTRUCK_UPDATE_SUCCESS_MSG = "Studio updated successfully";
export const STUDIOTRUCK_UPDATE_FAILURE_MSG = "Unable to update Studio";

//Role based permission constants
export const CREATE_LIVESHOT_PERMISSION = "Create Liveshot";
export const UPDATE_LIVESHOT_PERMISSION = "Update Liveshot";
export const UPDATE_LIVESHOT_STATUS_PERMISSION = "Update Liveshot Status";
export const DELETE_LIVESHOT_PERMISSION = "Delete Liveshot";
export const CREATE_SHOW_PERMISSION = "Create Show";
export const CREATE_CANVAS_PERMISSION = "Create Canvas";
export const UPDATE_CANVAS_PERMISSION = "Update Canvas";
export const LIVESHOT_SIGNAL_TAKE_CONTROL =
  "Update LiveshotSignal Take Control";
export const LIVESHOT_SIGNAL_GRAB_CONTROL =
  "Update LiveshotSignal Grab Control";
export const CREATE_LIVESHOT_SIGNAL = "Create LiveshotSignal";
export const CREATE_BLOCK_PERMISSION = "Create Block";
export const UPDATE_BLOCK_PERMISSION = "Update Block";
export const DELETE_BLOCK_PERMISSION = "Delete Block";
export const READ_ROUTER_SIGNAL_DETAILS_PERMISSION = "Read RouterSignalDetails";
export const READ_CONTROL_TRACKER_PERMISSION = "Read ControlTracker";
export const CREATE_DROP_IMPORT_PERMISSION = "Create Drop And Import Task";
export const READ_CONTROL_ROOM_DETAILS_PERMISSION = "Read ControlRoomDetails";
export const READ_CANVAS_PERMISSION = "Update Canvas";

export const DROP_AND_IMPORT_SUCCESS_MSG =
  "Signals are dropped and imported successfully ";
export const DROP_AND_IMPORT_FAILURE_MSG = "Unable to drop and import signals";
export const UPLOAD_SOURCE_DESTINATION_SUCCESS_MSG =
  "CSV file uploaded successfully";
export const UPLOAD_VENUE_DETAILS = "CSV file uploaded successfully";
export const LIVESHOT_SEARCH_FAILURE_MSG =
  "Unable to fetch liveshots for search";

export const MANAGE_LIVESHOT_SIGNAL_PERMISSION = "Manage LiveshotSignal";
export const SUPER_ADMIN = "Super Admin";
export const OPERATIONS_ADMIN = "Operations_Admin";
export const VIEW_ONLY_ALT = "View_Only_Alt";

// export const UPLOAD_USER_SSO_NUMBER_SUCCESS_MSG = 'User Sso Number Uploaded Successfully';
export const STUDIOPINDATA_SAVE_SUCCESS_MSG = "Pin data saved Successfully";
export const STUDIOPINDATA_SAVE_FAILURE_MSG = "Unable to save Pin Data";
export const STUDIOPINDATA_UPDATED_SUCCESS_MSG =
  "studio pin data updated successfully";
export const STUDIOPINDATA_UPDATE_FAILURE_MSG = "pin data update fail";

export const ROLE_PERMISSION = {
  1: ["Management Tools"],
  2: ["Management Tools"],
  3: ["Management Tools"],
  4: ["Management Tools"],
  5: ["Management Tools"],
  6: ["Management Tools"],
};

export const CONTROL_TRACKER_PERMISSION = [1, 2, 3, 4, 5, 6];
export const CANVAS_NOTES_LENGTH = 500;
export const INPUT_MAX_LENGTH = 255;

export const CONTACT_ERR_MESSAGE =
  "Phone number must be entered in one of these formats:\n### ### #####\n###########\n+44 #### ######\n##########\n(###)#######\n(###) ###-####";

export const ERROR_HEADING = "Error";
export const ERROR_MANDATORY_FIELDS_MISSING =
  "Not all Mandatory fields are present. Please check all mandatory fields in the left panel and try again. Once all fields are complete, this liveshot can be submitted via status update.";
