import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import * as actions from "../../store/actions/index";
//import CommanModal from "../../shared/commanModal/CommanModal";
import Backdrop from "../../components/shared/UI/Backdrop/Backdrop";
//import ErrorMessage from "../../shared/ErrorMessage";
import VenueModal from "../modals/venueModal/VenueModal";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import "./StudioTruck.css";
import CloseLogo from "../../assets/icons/Close.svg";
import invertedArrow from "../../utils/dropdownArrow/dropdownArrow";
import { DebounceInput } from "react-debounce-input";
import { VENUE_TYPES } from "../../constants/PageConstants";
import { CustomInput } from "../shared/UI/Input/CustomInput";
import LiveshotNameSuggestion from "../liveshot/LiveshotNameSuggestion/LiveshotNameSuggestion";
import ClickAwayListenerHoc from "../shared/UI/ClickAwayListenerHoc";
import { getContactList } from "../shared/utility";
import * as Constants from "../shared/Constants";

const StudioTruck = (props) => {
  const {
    lsId,
    onPatchStudioTruck,
    token,
    studioTruckList,
    studioTruckTypes,
    onSelectedStudioTruck,
    onFetchStudioTruckTypes,
    stIFB,
    stPL,
    stPHO,
    stName,
    stPhone,
    stEmail,
    stContact,
    venueName,
    studioCost,
    stDesc,
    studioDisabled,
    studioId,
    studioLocation,
    venueType,
    type,
    tempVType,
    showVenue,
    venueCity,
    venueRegion,
    venueCountry,
    venueSkype,
    venueQuicklink,
    venueWebOther,
    roomCore,
    studioDefaultTransmissionPath,
    onUpdateLiveShotOnChange,
    stTypeId,
    formInfo,
    read,
    hideVenueTypes,
    onChangeVenueType,
    handleOnClear,
    onChangeType,
    onChangeCity,
    onChangeRegion,
    onChangeCountry,
    onChangeSkype,
    onChangeQuicklink,
    onChangeWebOther,
    onChangeTxpath,
    onChangeLiveshotNotes,
    onChangePoNumber,
    onChangeVenueContactName,
    onChangeVenueContactPhone,
    onChangeVenueContactEmail,
    emailError,
    phoneError,
    onStudioVenueNameChange,
    permission,
    liveshotNotes,
    onChangeContactDetails,
  } = props;

  const initialValues = {};
  const history = useHistory();

  const [studioName, setStudioName] = useState(null);
  const [studioContact, setStudioContact] = useState(null);
  const [debouncedStudioContact, setDebouncedStudioContact] = useState(
    () => studioContact
  );
  const [studioPhone, setStudioPhone] = useState(null);
  const [studioEmail, setStudioEmail] = useState(null);
  const [venueStCity, setStVenueCity] = useState(null);
  const [venueStRegion, setStVenueRegion] = useState(null);
  const [venueStName, setVenueStName] = useState(null);
  const [venueStCountry, setStVenueCountry] = useState(null);
  const [stSkype, setStSkype] = useState(null);
  const [stQuicklink, setStQuicklink] = useState(null);
  const [stWebOther, setStWebOther] = useState(null);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState(initialValues);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showVenueForm, setShowVenueForm] = useState(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [stTxPath, setStTxPath] = useState(null);
  const [stLiveshotNotes, setStLiveshotNotes] = useState("");
  const [studioNotes, setStudioNotes] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [vtype, setvType] = useState("");
  const [onClear, setOnClear] = useState(false);
  const [over] = useState(false);
  const contactList = getContactList(studioTruckList, studioName, studioId);
  const initialContactValue = contactList && [...contactList.keys()];
  const [showContactSuggestions, setShowContactSuggestions] = useState(false);
  const [filteredContactList, setFilteredContactList] =
    useState(initialContactValue);

  const [stype, setStype] = useState(stTypeId);

  const studioTruckTypeRef = useRef(null);
  const studioContactRef = useRef(null);

  useEffect(() => {
    setReadOnly(read);
  }, [read]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSuggestions(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useEffect(() => {
    onFetchStudioTruckTypes(token);
  }, [onFetchStudioTruckTypes, token]);
  useEffect(() => {
    setStudioName(stName);
    setStudioPhone(stPhone);
    setStudioEmail(stEmail);
    setStudioContact(stContact);
    setStudioNotes(stDesc);
    setStVenueCity(venueCity);
    setStVenueRegion(venueRegion);
    setStVenueCountry(venueCountry);
    setStSkype(venueSkype);
    setStQuicklink(venueQuicklink);
    setStWebOther(venueWebOther);
    setvType(venueType);
    setStTxPath(studioDefaultTransmissionPath);
    setStLiveshotNotes(liveshotNotes);
    if (stName) {
      setAddButtonDisabled(false);
    } else {
      setAddButtonDisabled(true);
    }
  }, [
    stName,
    stPhone,
    stEmail,
    stContact,
    studioCost,
    stDesc,
    studioLocation,
    venueCity,
    venueRegion,
    venueCountry,
    venueType,
    venueSkype,
    venueQuicklink,
    venueWebOther,
    stTypeId,
    studioDefaultTransmissionPath,
    vtype,
  ]);

  const closeModal = () => {
    setShowVenueForm(false);
  };

  const studioList = studioTruckList.filter(
    (list) => list.softDelete === false && list.studioName != null
  );

  const onStudioNameChange = (e) => {
    onStudioVenueNameChange(e);
    setStudioName(e.target.value);
    let value = e.target.value;
    if (value !== undefined) {
      const filSuggestion = studioList.filter(
        (suggestion) =>
          suggestion.studioName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setActiveSuggestion(activeSuggestion);
      setFilteredSuggestions(filSuggestion);
      setShowSuggestions(true);
    }
  };

  // Multiple Venue contact name suggestions
  const onVenueContactChange = (e) => {
    onChangeVenueContactName(e);
    let value = (e.target.value || "").replace(/\t/i, " ");
    if (value !== undefined) {
      const filSuggestion = initialContactValue.filter(
        (suggestion) =>
          suggestion
            .toLowerCase()
            .replace(/\t/i, " ")
            .indexOf(value.toLowerCase()) > -1
      );
      setFilteredContactList(filSuggestion);
      setShowContactSuggestions(true);
    }
  };

  const onClickContactList = (name = "") => {
    setFilteredContactList(initialContactValue);
    setShowContactSuggestions(false);
    let selectedContact = contactList.get(name.toLowerCase());
    if (selectedContact) {
      const contactDetails = {
        liveshotContactName: selectedContact.studioContact,
        liveshotContactPhone: selectedContact.studioPhone,
        liveshotContactEmail: selectedContact.studioEmail,
      };
      onChangeContactDetails(contactDetails, selectedContact);
      selectedContact && onUpdateLiveShotOnChange(lsId, contactDetails, token);
    }
  };

  const onClickList = (name, selectedStudio) => {
    setActiveSuggestion(0);
    setFilteredSuggestions(initialValues);
    setShowSuggestions(false);
    setStudioName(name);
    if (selectedStudio !== null) {
      let Id = selectedStudio.stId;
      //  Old logic for fetching COMMS paths when a venue is clicked
      onSelectedStudioTruck(token, Id, lsId, true);
      if (selectedStudio.studioTypeName === "VIDEO CONFERENCE") {
        onUpdateLiveShotOnChange(
          lsId,
          {
            stId: Id,
            venue: name,
            venueType: selectedStudio.studioTypeName,
            skype: selectedStudio.skype,
            quickLink: selectedStudio.quickLink,
            webOther: selectedStudio.webOther,
            city: null,
            region: null,
            country: null,
            tx: selectedStudio.studioTx,
            liveshotContactName: selectedStudio.studioContact,
            liveshotContactPhone: selectedStudio.studioPhone,
            liveshotContactEmail: selectedStudio.studioEmail,
            poNumber: selectedStudio.poNumber,
            liveshotNote: selectedStudio.liveshotNote,
          },
          token
        );
      } else if (
        selectedStudio.studioTypeName === "AD HOC FEED" ||
        selectedStudio.studioTypeName === "TRUCK"
      ) {
        onUpdateLiveShotOnChange(
          lsId,
          {
            stId: Id,
            venue: name,
            venueType: selectedStudio.studioTypeName,
            city: null,
            region: null,
            country: null,
            skype: null,
            quickLink: null,
            webOther: null,
            tx: selectedStudio.studioTx,
            liveshotContactName: selectedStudio.studioContact,
            liveshotContactPhone: selectedStudio.studioPhone,
            liveshotContactEmail: selectedStudio.studioEmail,
            poNumber: selectedStudio.poNumber,
            liveshotNote: selectedStudio.liveshotNote,
          },
          token
        );
      } else {
        onUpdateLiveShotOnChange(
          lsId,
          {
            stId: Id,
            venue: name,
            venueType: selectedStudio.studioTypeName,
            city: selectedStudio.city,
            region: selectedStudio.region,
            country: selectedStudio.country,
            skype: null,
            quickLink: null,
            webOther: null,
            tx: selectedStudio.studioTx,
            liveshotContactName: selectedStudio.studioContact,
            liveshotContactPhone: selectedStudio.studioPhone,
            liveshotContactEmail: selectedStudio.studioEmail,
            poNumber: selectedStudio.poNumber,
            liveshotNote: selectedStudio.liveshotNote,
          },
          token
        );
      }
    }
  };

  const onClose = () => {
    setStudioName(null);
    setStudioPhone(null);
    setStudioEmail(null);
    setStudioContact(null);
    setStTxPath(null);
    setStudioNotes(null);
    setStVenueCity("");
    setStVenueRegion("");
    setStVenueCountry("");
    setStSkype("");
    setStQuicklink("");
    setStWebOther("");
    onSelectedStudioTruck(token, 0, lsId);
    onUpdateLiveShotOnChange(
      lsId,
      {
        venue: "",
        venueType: tempVType,
        stId: 0,
        city: "",
        region: "",
        country: "",
        skype: "",
        quickLink: "",
        webOther: "",
        tx: "",
      },
      token
    );
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setShowSuggestions(false);
    }
  };

  const getSuggestionList = (list, field) => {
    let suggestionsListComponent = [];
    if (
      (showSuggestions || showContactSuggestions) &&
      studioName &&
      list.length > 0
    ) {
      suggestionsListComponent = list.sort(function (a, b) {
        if (field) {
          a = a[field];
          b = b[field];
        }
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    }

    return suggestionsListComponent;
  };

  const handleContactNameClick = (e) => {
    setShowContactSuggestions(!showContactSuggestions);
    setFilteredContactList(initialContactValue);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  let venueForm = null;
  if (showVenueForm) {
    venueForm = (
      <Fragment>
        <Backdrop show={showVenueForm} clicked={closeModal} />
        <VenueModal
          closeModal={closeModal}
          stName={stName}
          stContact={stContact}
          stPhone={stPhone}
          stEmail={stEmail}
          studioCost={studioCost}
          stDesc={stDesc}
          studioId={studioId}
          venueStCity={venueStCity}
          venueStRegion={venueStRegion}
          venueStCountry={venueStCountry}
          stSkype={stSkype}
          stQuicklink={stQuicklink}
          stWebOther={stWebOther}
          stTxPath={stTxPath}
          showDeleteButton={addButtonDisabled}
          lsId={lsId}
          onDelete={onClose}
          stTypeId={type}
          stLiveshotNotes={stLiveshotNotes}
        />
      </Fragment>
    );
  }
  return (
    <div className="studioform-detail-container">
      {venueForm}
      <div ref={wrapperRef} className="liveshotstudio">
        <div className="venue-name-leftpnl field">
          <div className="input-label"> Venue Name</div>
          <DebounceInput
            className={`venueNameInput`}
            type="text"
            name="studio/truck"
            debounceTimeout={500}
            autoComplete="off"
            value={studioName ? studioName : ""}
            placeholder="ENTER VENUE NAME"
            onChange={(e) => onStudioNameChange(e)}
            onKeyPress={(e) => onKeyPress(e)}
            disabled={permission}
            maxLength={Constants.INPUT_MAX_LENGTH}
          />
        </div>
        <LiveshotNameSuggestion
          name={"venueNameOption"}
          LSNameLists={getSuggestionList(filteredSuggestions, "studioName")}
          showsuggestion={showSuggestions}
          closeSuggestion={() => {
            setShowSuggestions(false);
          }}
          selectLiveshotName={onClickList}
          optionField={"studioName"}
        />
        <div className="venue-type-container">
          <div className="venue-type-leftpnl field">
            <div className="input-label"> Venue Type</div>
            <div className="vtype-container">
              <div
                className={`${
                  !permission && showVenue
                    ? "dropdown-focused-bg v-type"
                    : "dropdown-black-bg v-type"
                } ${!vtype ? "dropdown-req" : "dropdown-filled"} ${
                  permission ? "restricted" : ""
                }`}
                ref={studioTruckTypeRef}
                value={type ? type : ""}
                onChange={onChangeType}
                onClick={() => {
                  !permission && hideVenueTypes();
                }}
                disabled={permission}
              >
                <div
                  style={{ color: showVenue && "#141414" }}
                  className={vtype ? "hidden" : "venue-placeholder wrap-text"}
                >
                  {!tempVType || !vtype
                    ? "e.g. 30R LOCATION, SKYPE, QUICKLINK..."
                    : tempVType}
                </div>
                <div className={vtype ? "info wrap-text" : "hidden"}>
                  {vtype}
                </div>
                <i
                  style={
                    !showVenue && !vtype
                      ? { color: "#7D7D7D" }
                      : showVenue || permission
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  className={`fas fa-angle-down venuedrop ${
                    !showVenue ? "dropIcon" : ""
                  }`}
                ></i>
                <i
                  style={
                    !showVenue || permission
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  className="fas fa-angle-up venuedrop"
                ></i>
              </div>
            </div>
            <div className="venue-position">
              {showVenue && !permission && (
                <ClickAwayListenerHoc
                  nodeRef={studioTruckTypeRef.current}
                  elementToIgnore="studioTruckOption"
                  hideElement={hideVenueTypes}
                >
                  <ul className="ul-list-item-container">
                    {studioTruckTypes &&
                      studioTruckTypes.map((item) => (
                        <li
                          name="studioTruckOption"
                          className={
                            "ul-list-item" +
                            (over || vtype == item.studioTypeName
                              ? " list-item-bg"
                              : "")
                          }
                          key={item.studioTypeId}
                          value={item.studioTypeId}
                          onClick={(e) => onChangeVenueType(e)}
                        >
                          {item.studioTypeName}
                        </li>
                      ))}
                  </ul>
                </ClickAwayListenerHoc>
              )}
            </div>
          </div>
          <div
            className="venue-city-container field-r"
            style={VENUE_TYPES.includes(vtype) ? { display: "none" } : {}}
          >
            <div className="venue-city-leftpnl">
              <div className="input-label">City</div>
              <DebounceInput
                className={`venueCityInput`}
                debounceTimeout={1500}
                autoComplete="off"
                value={venueStCity ? venueStCity : ""}
                placeholder="ENTER CITY"
                onChange={(e) => onChangeCity(e)}
                disabled={permission}
                maxLength={Constants.INPUT_MAX_LENGTH}
              />
            </div>
            <div className="venue-region-leftpnl">
              <div className="input-label">St, Province, or Region</div>
              <DebounceInput
                className={`venueRegionInput`}
                debounceTimeout={1500}
                autoComplete="off"
                value={venueStRegion ? venueStRegion : ""}
                placeholder="ENTER ST, PROVINCE, OR REGION"
                onChange={(e) => onChangeRegion(e)}
                disabled={permission}
                maxLength={Constants.INPUT_MAX_LENGTH}
              />
            </div>
          </div>
          <div className={"venue-country-container field-r"}>
            <div
              className={`${
                [
                  "VIDEO CONFERENCE",
                  "TRUCK",
                  "AD HOC FEED",
                  "30R LOCATION",
                ].includes(vtype)
                  ? "venue-clear-button padding-top"
                  : "venue-clear-button"
              }`}
            >
              <button
                className={`venue-type-clear-btn ${
                  permission ? "restricted" : ""
                }`}
                type="button"
                onClick={permission ? undefined : handleOnClear}
              >
                Clear
              </button>
            </div>
            <div
              className="venue-country-leftpnl"
              style={VENUE_TYPES.includes(vtype) ? { display: "none" } : {}}
            >
              <div className="input-label">Country</div>
              <DebounceInput
                className={`venueCountryInput`}
                debounceTimeout={1500}
                autoComplete="off"
                value={venueStCountry ? venueStCountry : ""}
                onChange={(e) => onChangeCountry(e)}
                onKeyPress={(e) => onKeyPress(e)}
                disabled={permission}
                maxLength={Constants.INPUT_MAX_LENGTH}
              />
            </div>
          </div>
          <div
            className="vc-container"
            style={
              vtype === "VIDEO CONFERENCE"
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <div className="venue-vc-container">
              <div>
                <div className="input-label">Skype:</div>
                <DebounceInput
                  type="text"
                  debounceTimeout={1500}
                  className={`skypeInput transform-input`}
                  value={stSkype ? stSkype : ""}
                  onKeyPress={(e) => onKeyPress(e)}
                  onChange={(e) => onChangeSkype(e)}
                  autoComplete="off"
                  disabled={permission}
                />
              </div>
              <div>
                <div className="input-label">Quicklink:</div>
                <DebounceInput
                  type="text"
                  debounceTimeout={1500}
                  className={`quicklinkInput transform-input`}
                  onChange={(e) => onChangeQuicklink(e)}
                  autoComplete="off"
                  onKeyPress={(e) => onKeyPress(e)}
                  value={stQuicklink ? stQuicklink : ""}
                  disabled={permission}
                />
              </div>
              <div>
                <div className="input-label">Web/Other:</div>
                <DebounceInput
                  type="text"
                  debounceTimeout={1500}
                  className={`webotherInput transform-input`}
                  autoComplete="off"
                  onChange={(e) => {
                    onChangeWebOther(e);
                  }}
                  onKeyPress={(e) => onKeyPress(e)}
                  value={stWebOther ? stWebOther : ""}
                  disabled={permission}
                />
              </div>
            </div>
          </div>
          <div
            className="vc-container"
            style={
              vtype === "HOME CAM" ||
              vtype === "ZOOM" ||
              vtype === "SKYPE" ||
              vtype === "QLINK" ||
              vtype === "WEB"
                ? { display: "flex" }
                : { display: "none" }
            }
          ></div>
        </div>

        <div className="studio-contact">
          <div className="tx-number-leftpnl field">
            <span className="input-label">Tx</span>
            <DebounceInput
              element={CustomInput}
              overrideStyle={true}
              debounceTimeout={1500}
              className={`venueTxInput transform-input`}
              name="Tx"
              maxLength={1000}
              value={stTxPath ? stTxPath : ""}
              autoComplete="off"
              onChange={(e) => onChangeTxpath(e)}
              onKeyPress={(e) => onKeyPress(e)}
              disabled={permission}
            />
          </div>
          <div className="contact-name-leftpnl field">
            <div className="input-label"> Venue Contact Name</div>
            <div
              className="contact-container"
              ref={studioContactRef}
              onClick={handleContactNameClick}
            >
              <DebounceInput
                element={CustomInput}
                overrideStyle={true}
                maxLength={Constants.INPUT_MAX_LENGTH}
                autoSize={{ minRows: 1, maxRows: 2 }}
                className={`venueContactInput contactName`}
                debounceTimeout={1500}
                autoComplete="off"
                value={studioContact || ""}
                onChange={(e) => onVenueContactChange(e)}
                onKeyPress={(e) => onKeyPress(e)}
                disabled={permission}
              />
              <i
                id="dropdown"
                style={
                  permission || contactList.size < 2
                    ? { display: "none" }
                    : { display: "block" }
                }
                className={`${
                  showContactSuggestions
                    ? "fas fa-angle-up"
                    : "fas fa-angle-down"
                } contact-icon ${!studioContact && "placeholder-icon"}`}
              ></i>
            </div>
          </div>
          <ClickAwayListenerHoc
            nodeRef={studioContactRef.current}
            elementToIgnore="studioContactOption"
            hideElement={() => {
              setShowContactSuggestions(false);
            }}
          >
            <LiveshotNameSuggestion
              name="studioContactOption"
              LSNameLists={getSuggestionList(filteredContactList)}
              showsuggestion={showContactSuggestions && contactList.size > 1}
              closeSuggestion={() => {
                setShowContactSuggestions(false);
              }}
              selectLiveshotName={onClickContactList}
              selectedList={studioContact ? studioContact : ""}
            />
          </ClickAwayListenerHoc>
          <div className="contact-phone-leftpnl field">
            <div className="input-label"> Venue Contact Phone</div>
            <DebounceInput
              element={CustomInput}
              overrideStyle={true}
              maxLength={1000}
              autoSize={{ minRows: 1, maxRows: 2 }}
              className={`venueContactInput contactPhone`}
              debounceTimeout={500}
              autoComplete="off"
              value={studioPhone || ""}
              onChange={(e) => onChangeVenueContactPhone(e)}
              onKeyPress={(e) => onKeyPress(e)}
              disabled={permission}
            />
          </div>
          <div className="contact-email-leftpnl field">
            <div className="input-label"> Venue Contact Email</div>
            <DebounceInput
              element={CustomInput}
              overrideStyle={true}
              maxLength={Constants.INPUT_MAX_LENGTH}
              autoSize={{ minRows: 1, maxRows: 2 }}
              className={`venueContactInput contactEmail`}
              debounceTimeout={1500}
              autoComplete="off"
              value={studioEmail || ""}
              onChange={(e) => onChangeVenueContactEmail(e)}
              onKeyPress={(e) => onKeyPress(e)}
              disabled={permission}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    studioTruckList: state.studioTruckInfo.studioTruckList,
    showModal: state.studioTruckOperations.showModal,
    studioTruckTypes: state.studioTruckInfo.studioTruckTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPatchStudioTruck: (studioFormData, token, stId) =>
      dispatch(actions.patchStudioTruck(studioFormData, token, stId)),
    onFetchStudioTruckList: (token) =>
      dispatch(actions.fetchStudioTruckList(token)),
    onFetchStudioTruckTypes: (token) =>
      dispatch(actions.fetchStudioTruckTypes(token)),
    onSelectedStudioTruck: (token, stId, lsId, setPin = undefined) =>
      dispatch(actions.fetchStudioTruckData(token, stId, lsId, setPin)),
    onCloseCommanModal: (token) =>
      dispatch(actions.closeStudioTruckModal(token)),
    onUpdateLiveShotOnChange: (liveshotId, formData, token) =>
      dispatch(actions.updateLiveShotOnChange(liveshotId, formData, token)),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(StudioTruck)
);

StudioTruck.defaultProps = {
  onChangeContactDetails: (props) => {},
};
