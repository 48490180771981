import React, { useState, useEffect } from "react";
import "./Modal.css";
import Backdrop from "../Backdrop/Backdrop";

import successIcon from "../../../../assets/icons/Info.svg";
import errorIcon from "../../../../assets/icons/Error.svg";
import infoWarning from "../../../../assets/icons/routes-duplicate-info.svg";
import warningIcon from "../../../../assets/icons/Warning.svg";
import closeIcon from "../../../../assets/icons/Close-icon.svg";
import infoLogo from "../../../../assets/icons/Info.svg";

const Modal = (props) => {
  let icon = errorIcon;
  let alt = "Error-icon";
  let style = "modal-type-error";
  let primaryBtnTxt = "";
  let secondaryBtnTxt = "";
  if (props.type === "confirm") {
    primaryBtnTxt = props.okBtnText ? props.okBtnText : "YES";
    secondaryBtnTxt = props.clsBtnText ? props.clsBtnText : "NO";
  } else {
    primaryBtnTxt = props.clsBtnText ? props.clsBtnText : "CLOSE";
  }
  if (props.type === "confirm") {
    icon = warningIcon;
    alt = "Warning-icon";
    style = "modal-type-warning";
  }
  if (props.warning != undefined || props.type === "warning") {
    icon = infoWarning;
    alt = "Error-icon";
    style = "modal-type-warning";
  }
  if (props.success != undefined || props.type === "success") {
    icon = successIcon;
    alt = "Plus Logo";
    style = "modal-type-success";
  }
  if (props.info != undefined || props.type === "info") {
    icon = infoLogo;
    alt = "Info-icon";
    style = "modal-type-info";
  }
  if (props.icon) {
    icon = props.icon;
  }

  return (
    props.showModal && (
      <>
        <Backdrop
          show={props.showModal}
          clicked={props.type === "confirm" ? () => {} : props.onModalClose}
        />
        <div className={"modal-container " + style}>
          <div className="modal-header-row">
            <img className="modal-icon" alt={alt} src={icon} />
            <h4 className="modal-label"> {props.title} </h4>
            {props.type !== "confirm" && (
              <img
                className="modal-close-icon"
                type="button"
                alt="Warning_Close_Logo"
                onClick={props.onModalClose}
                src={closeIcon}
              />
            )}
          </div>
          <div className="modal-content-container">
            {props.content &&
              props.content
                .split("\n")
                .map((line, index) =>
                  line.trim() == "" ? (
                    <br key={index} />
                  ) : (
                    <span key={index}>{line}</span>
                  )
                )}
          </div>
          <div className="modal-btn-container">
            <input
              type="button"
              className="modal-primary-btn"
              value={primaryBtnTxt}
              onClick={
                props.type === "confirm" ? props.onConfirm : props.onModalClose
              }
            />
            {props.type === "confirm" && (
              <input
                type="button"
                className="modal-secondary-btn"
                value={secondaryBtnTxt}
                onClick={props.onModalClose}
              />
            )}
          </div>
        </div>
      </>
    )
  );
};

export default Modal;
