import React from "react";
import { Tooltip } from "antd";

export const TabComponent = ({
  tabs,
  activeTab,
  onTabClick,
  onPlusIconClick,
  isModalOpen,
}) => {
  return (
    <div className="row router_buttons_container">
      <div className="router_buttons">
        {tabs.map((tab) => {
          return (
            <button
              key={tab.label}
              onClick={() => {
                onTabClick(tab.label);
              }}
              className={
                activeTab === tab.label ? "active ag-header-container" : null
              }
            >
              {tab.label}
              {tab.isPlusIcon && (
                <Tooltip placement="bottom" title={tab.toolTip}>
                  <i
                    className={`fa fa-plus-circle plus-icon ${
                      activeTab !== tab.label ? "disabledPlusIcon" : ""
                    }`}
                    data-testid="plus-icon"
                    onClick={() => onPlusIconClick()}
                  />
                </Tooltip>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};
