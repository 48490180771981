export const NORMAL_LIVESHOT_TYPE = "NORMAL_LIVESHOT_TYPE";

export const CURRENT_CANVAS = "CURRENT_CANVAS";
export const FETCH_CANVAS_LIST_SUCCESS = "FETCH_CANVAS_LIST_SUCCESS";
export const ADD_CAPABILITY = "ADD_CAPABILITY";

export const LOADING_START = "LOADING_START";
export const LOADING_END = "LOADING_END";

export const LIVESHOTS = "LIVESHOTS";
export const UPDATE_NORMAL_LIVESHOTS = "UPDATE_NORMAL_LIVESHOTS";
export const NORMAL_LIVESHOTS = "NORMAL_LIVESHOTS";
export const NO_LIVESHOTS = "NO_LIVESHOTS";
export const MAKE_FOLD = "MAKE_FOLD";
export const MAKE_UNFOLD = "MAKE_UNFOLD";
export const START_LIVESHOT_CREATION = "START_LIVESHOT_CREATION";
export const RESET_LIVESHOT_CREATION = "RESET_LIVESHOT_CREATION";

export const LIVESHOTSAVE_OBJECT_SUCCESS = "LIVESHOTSAVE_OBJECT_SUCCESS";

export const SEARCH_LIVESHOT_SUCCESS = "SEARCH_LIVESHOT_SUCCESS";
export const CITY_STATE_SUCCESS = "CITY_STATE_SUCCESS";
export const LIVE_SHOT_NAMES_SUCCESS = "LIVE_SHOT_NAMES_SUCCESS";
export const NEW_LIVE_SHOT = "NEW_LIVE_SHOT";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_START = "AUTH_START";
export const AUTH_END = "AUTH_END";
export const AUTH_REMOVE = "AUTH_REMOVE";

export const ROLE_LIST = "ROLE_LIST";
export const USER_LIST = "USER_LIST";
export const SET_CURRENT_USER_ROLE = "SET_CURRENT_USER_ROLE";
export const SET_USER_PERMISSION = "SET_USER_PERMISSION";
export const SET_CURRENT_USER_FACILITY = "SET_CURRENT_USER_FACILITY";
export const SET_USER_ROLE = "SET_USER_ROLE";

export const CLOSE_COMMAN_MODAL = "CLOSE_COMMAN_MODAL";
export const CLOSE_SEARCH_MODAL = "CLOSE_SEARCH_MODAL";
export const OPEN_SEARCH_MODAL = "OPEN_SEARCH_MODAL";
export const SHOW_ERROR_MODAL = "SHOW_ERROR_MODAL";
export const HIDE_ERROR_MODAL = "HIDE_ERROR_MODAL";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const SHOW_LOADER = "SHOW_LOADER";
export const SHOW_SEARCH_ERROR_MODAL = "SHOW_SEARCH_ERROR_MODAL";
export const HIDE_SEARCH_ERROR_MODAL = "HIDE_SEARCH_ERROR_MODAL";
export const UPDATE_LIVESHOT_INFO = "UPDATE_LIVESHOT_INFO";

export const LIVESHOT_ADDED_FROM_TEMPLATE_OR_DATE =
  "LIVESHOT_ADDED_FROM_TEMPLATE_OR_DATE";
export const RESET_ADDED_FROM_TEMPLATE_OR_DATE =
  "RESET_ADDED_FROM_TEMPLATE_OR_DATE";

export const LIVESHOT_ADDED_FROM_SEARCH = "LIVESHOT_ADDED_FROM_SEARCH";
export const RESET_ADDED_FROM_SEARCH = "RESET_ADDED_FROM_SEARCH";

export const CLOSE_NOTIFICATION_MODAL = "CLOSE_NOTIFICATION_MODAL";
export const OPEN_NOTIFICATION_MODAL = "OPEN_NOTIFICATION_MODAL";
export const SHOW_COMMON_POPUP_MODAL = "SHOW_COMMON_POPUP_MODAL";
export const SHOW_NOTIFICATION_BAR = "SHOW_NOTIFICATION_BAR";

export const FETCH_SHOW_LIST_SUCCESS = "FETCH_SHOW_LIST_SUCCESS";
export const FETCH_SHOW_DATA_SUCCESS = "FETCH_SHOW_DATA_SUCCESS";

export const DELTE_SHOW_SUCCESS = "DELTE_SHOW_SUCCESS";
export const UPDATE_SHOW = "UPDATE_SHOW";
export const RESET_SHOW_DATA = "RESET_SHOW_DATA";

export const FETCH_NETWORK_LIST_SUCCESS = "FETCH_NETWORK_LIST_SUCCESS";
export const SHOWSAVE_OBJECT_SUCCESS = "SHOWSAVE_OBJECT_SUCCESS";
export const CLOSE_SHOW_MODAL = "CLOSE_SHOW_MODAL";
export const FETCH_CONTROLROOM_LIST_SUCCESS = "FETCH_CONTROLROOM_LIST_SUCCESS";
export const CANVAS_CHECK_SUCCESS = "CANVAS_CHECK_SUCCESS";
export const CLOSE_CREATE_CANVAS_MODAL = "CLOSE_CREATE_CANVAS_MODAL";
export const FETCH_FACILITY_LIST_SUCCESS = "FETCH_FACILITY_LIST_SUCCESS";

export const FETCH_STUDIOTRUCK_LIST_SUCCESS = "FETCH_STUDIOTRUCK_LIST_SUCCESS";
export const FETCH_STUDIOTRUCK_TYPES_SUCCESS =
  "FETCH_STUDIOTRUCK_TYPES_SUCCESS";
export const STUDIOTRUCKSAVE_OBJECT_SUCCESS = "STUDIOTRUCKSAVE_OBJECT_SUCCESS";
export const STUDIOPINDATASAVE_OBJECT_SUCCESS =
  "STUDIOPINDATASAVE_OBJECT_SUCCESS";

export const FETCH_STUDIOTRUCK_DATA_SUCCESS = "FETCH_STUDIOTRUCK_DATA_SUCCESS";
export const RESET_STUDIOTRUCK_DATA = "RESET_STUDIOTRUCK_DATA";
export const RESET_STUDIOTRUCK_SUCCESS_MSG = "RESET_STUDIOTRUCK_SUCCESS_MSG";
export const SET_ST_DEFAULT_PIN = "SET_ST_DEFAULT_PIN";
export const RESET_ST_DEFAULT_PIN = "RESET_ST_DEFAULT_PIN";

export const RESET_SIGNALS_INFO = "RESET_SIGNALS_INFO";
export const ADD_SIGNAL = "ADD_SIGNAL";
export const REMOVE_SIGNAL = "REMOVE_SIGNAL";
export const FETCHSIGNALS = "FETCHSIGNALS";
export const CRPATHINBOUND = "CRPATHINBOUND";
export const AVAILABLE_SIGNAL = "AVAILABLE_SIGNAL";
export const GLOBALPATHINBOUND = "GLOBALPATHINBOUND";
export const EXTENDGLOBALPATHINBOUND = "EXTENDGLOBALPATHINBOUND";
export const CRPATHOUTBOUND = "CRPATHOUTBOUND";
export const GLOBALPATHOUTBOUND = "GLOBALPATHOUTBOUND";
export const IFB_CR_PATH = "IFB_CR_PATH";
export const IFB_GLOBAL_PATH = "IFB_GLOBAL_PATH";
export const PL_GLOBAL_PATH = "PL_GLOBAL_PATH";
export const PL_CR_PATH = "PL_CR_PATH";
export const PHO_CR_PATH = "PHO_CR_PATH";
export const PHOTX_CR_PATH = "PHOTX_CR_PATH";
export const PHOTX_GLOBAL_PATH = "PHOTX_GLOBAL_PATH";
export const PHO_GLOBAL_PATH = "PHO_GLOBAL_PATH";
export const ADD_GRABBED_SIGNAL = "ADD_GRABBED_SIGNAL";
export const ADD_GLOBAL_PINS = "ADD_GLOBAL_PINS";
export const SHOW_SIGNAL_MODAL = "SHOW_SIGNAL_MODAL";
export const HIDE_SIGNAL_MODAL = "HIDE_SIGNAL_MODAL";
export const SHOW_SIGNAL_ERROR_MODAL = "SHOW_SIGNAL_ERROR_MODAL";
export const HIDE_SIGNAL_ERROR_MODAL = "HIDE_SIGNAL_ERROR_MODAL";
export const SHOW_SIGNAL_ERROR_VALUES_MODAL = "SHOW_SIGNAL_ERROR_VALUES_MODAL";
export const HIDE_SIGNAL_ERROR_VALUE_MODAL = "HIDE_SIGNAL_ERROR_VALUE_MODAL";
export const ADDINBOUNDAVAILSHAREDSIGNALS = "ADDINBOUNDAVAILSHAREDSIGNALS";
export const ADDPLAVAILSHAREDSIGNALS = "ADDPLAVAILSHAREDSIGNALS";
export const ADDPHOAVAILSHAREDSIGNALS = "ADDPHOAVAILSHAREDSIGNALS";
export const REMOVEINBOUNDAVAILSHAREDSIGNALS =
  "REMOVEINBOUNDAVAILSHAREDSIGNALS";
export const ADDOUTBOUNDAVAILSHAREDSIGNALS = "ADDOUTBOUNDAVAILSHAREDSIGNALS";
export const REMOVEOUTBOUNDAVAILSHAREDSIGNALS =
  "REMOVEOUTBOUNDAVAILSHAREDSIGNALS";
export const ADDIFBAVAILSHAREDSIGNALS = "ADDIFBAVAILSHAREDSIGNALS";
export const REMOVEIFBAVAILSHAREDSIGNALS = "REMOVEIFBAVAILSHAREDSIGNALS";
export const REMOVEPLAVAILSHAREDSIGNALS = "REMOVEPLAVAILSHAREDSIGNALS";
export const REMOVEPHOAVAILSHAREDSIGNALS = "REMOVEPHOAVAILSHAREDSIGNALS";
export const ADDREDBORDER = "ADDREDBORDER";
export const REMOVEREDBORDER = "REMOVEREDBORDER";
export const SHAREDGLOBALPATHINBOUND = "SHAREDGLOBALPATHINBOUND";
export const SHAREDGLOBALPATHOUTBOUND = "SHAREDGLOBALPATHOUTBOUND";
export const SHAREDGLOBALPATHIFB = "SHAREDGLOBALPATHIFB";
export const SHAREDGLOBALPATHPHOTX = "SHAREDGLOBALPATHPHOTX";
export const SHAREDGLOBALPATHPL = "SHAREDGLOBALPATHPL";
export const SHAREDGLOBALPATHPHO = "SHAREDGLOBALPATHPHO";
export const SETPINERROR = "SETPINERROR";
export const RESETPINERROR = "RESETPINERROR";

export const SOCKJS_SUBSCRIBE = "SOCKJS_SUBSCRIBE";
export const SOCKJS_UNSUBSCRIBE = "SOCKJS_UNSUBSCRIBE";
export const SOCKJS_CONNECT = "SOCKJS_CONNECT";
export const SOCKJS_NON_GRAB = "SOCKJS_NON_GRAB";
export const SHOW_DATE = "SHOW_DATE";
export const LIVESHOTS_INFO = "LIVESHOTS_INFO";
export const LIVESHOT_INFO = "LIVESHOT_INFO";
export const NULL_SHOWDATE_CHECK = "NULL_SHOWDATE_CHECK";

export const FETCH_RESOURCETRACKER_DATA = "FETCH_RESOURCETRACKER_DATA";
export const FETCH_VENUE_DATA = "FETCH_VENUE_DATA";
export const FETCH_VENUE_DETAILS = "FETCH_VENUE_DETAILS";
export const UPLOAD_VENUE_DETAILS = "UPLOAD_VENUE_DETAILS";

export const FETCH_ALL_SIP = "FETCH_ALL_SIP";
export const FETCH_ROUTER_SOURCES = "FETCH_ROUTER_SOURCES";
export const FETCH_PIN_NUMBER = "FETCH_PIN_NUMBER";
export const FETCH_SIP_STATE = "FETCH_SIP_STATE";
export const FETCH_ROUTER_DESTINATIONS = "FETCH_ROUTER_DESTINATIONS";
export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const FETCH_ROOM_DETAILS = "FETCH_ROOM_DETAILS";
export const FETCH_SIGNAL_TYPE_DETAILS = "FETCH_SIGNAL_TYPE_DETAILS";
export const FETCH_PINS_TABLE_INFO = "FETCH_PINS_TABLE_INFO";
export const FETCH_USER_INFO = "FETCH_USER_INFO";
export const FETCH_PIN_MODAL_INFO = "FETCH_PIN_MODAL_INFO";

export const SET_ABS_LS_PREFERENCE = "SET_ABS_LS_PREFERENCE";
export const ARM_ROUTING = "ARM_ROUTING";
export const SET_ACTIVE_LIVESHOT = "SET_ACTIVE_LIVESHOT";
export const SET_NEXT_ACTIVE_LIVESHOT = "SET_NEXT_ACTIVE_LIVESHOT";
export const KEY_SHORT_CUT = "KEY_SHORT_CUT";
export const DEVICE_STREAM_DECK = "DEVICE_STREAM_DECK";
export const SET_PRESSED_KEY = "SET_PRESSED_KEY";

export const FETCH_CONTROL_ROOM_DETAILS = "FETCH_CONTROL_ROOM_DETAILS";

export const STORE_ARR_POSITION = "STORE_ARR_POSITION";
export const FORMATTED_ROUTER_SOURCES = "FORMATTED_ROUTER_SOURCES";
export const ADD_SIP_LIST = "ADD_SIP_LIST";
export const UPDATE_LIVESHOT_SIGNAL = "UPDATE_LIVESHOT_SIGNAL";

export const REMOVE_DELETED_LIVESHOT_SIGNAL = "REMOVE_DELETED_LIVESHOT_SIGNAL";
