import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./VenueContactForm.css";
import { ReactComponent as Trash_icon } from "../../assets/icons/Trash-gray.svg";
import TextArea from "antd/es/input/TextArea";
import * as Constants from "../shared/Constants";
import { CustomInput } from "../shared/UI/Input/CustomInput";
import { DebounceInput } from "react-debounce-input";
const VenueContactForm = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      fetchContact: fetchContact,
    };
  });
  const { contactList, setInvalidContact } = props;
  const [contactName, setContactName] = useState(null);
  const [contactPhone, setContactPhone] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);

  const [selectedContact, setSelectedContact] = useState(0);
  const [contactItemList, setContactItemList] = useState([]);

  const checkPhoneError = (phoneNo) => {
    if (!phoneNo) {
      return false;
    }
    let usphoneno = /^([0-9]{3})[- ]([0-9]{3})[- ]([0-9]{4})$/;
    let ukphoneno = /^\+44 ([0-9]{4})[ ]([0-9]{6})$/;
    let phoneno = /^([0-9]{10})$/;
    let phonenum = /^\(([0-9]{3})\)[- ]([0-9]{3})[- ]([0-9]{4})$/;
    let phonenumber = /^\(([0-9]{3})\)[ ]([0-9]{3})[ ]([0-9]{4})$/;
    return (
      phoneNo !== null &&
      phoneNo !== "" &&
      !phoneNo.match(usphoneno) &&
      !phoneNo.match(ukphoneno) &&
      !phoneNo.match(phoneno) &&
      !phoneNo.match(phonenum) &&
      !phoneNo.match(phonenumber)
    );
  };
  const checkEmailError = (email) => {
    let emailPatterRegx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return email && email != "" ? !email.match(emailPatterRegx) : false;
  };

  const udpateContactName = (name) => {
    setContactName(name);
    if (contactItemList[selectedContact]) {
      contactItemList[selectedContact].name = name;
      setContactItemList([...contactItemList]);
    }
  };
  const updateContactPhone = (phone) => {
    const phoneNo = (phone || "").toUpperCase();
    setContactPhone(phoneNo);
    if (contactItemList[selectedContact]) {
      contactItemList[selectedContact].phone = phoneNo;
      setContactItemList([...contactItemList]);
    }
  };
  const updateContactEmail = (email) => {
    const emailId = (email || "").toUpperCase();
    setContactEmail(emailId);
    if (contactItemList[selectedContact]) {
      contactItemList[selectedContact].email = emailId;
      setContactItemList([...contactItemList]);
    }
  };

  const newContactDetail = {
    name: "",
    phone: "",
    email: "",
  };

  const addNewContact = () => {
    let newContactIndex = contactItemList.length;
    setContactItemList([...contactItemList, newContactDetail]);
    setSelectedContact(newContactIndex);
  };

  const deleteContact = (index) => {
    if (index != -1 && index < contactItemList.length) {
      let contacts = [...contactItemList];
      contacts.splice(index, 1);
      setContactItemList([...contacts]);
      setSelectedContact(index - 1 < 0 ? 0 : index - 1);
    }
  };

  const fetchContact = () => {
    let contactList = [...contactItemList];
    contactList.forEach((contact) => {
      contact.phoneError = checkPhoneError(contact.phone);
      contact.emailError = checkEmailError(contact.email);
    });
    return contactList;
  };

  const clearInputValues = () => {
    contactItemList[selectedContact] = newContactDetail;
    setContactItemList([...contactItemList]);
    setSelectedContact(selectedContact);
  };

  const validateMandatory = (contactItemList) => {
    let isInvalid = false;
    contactItemList.forEach((contact) => {
      isInvalid = contact.name == "" || contact.phone == "";
      if (isInvalid) {
        return;
      }
    });
    setInvalidContact(isInvalid);
  };

  useEffect(() => {
    if (contactList && contactList.length != 0) {
      setContactItemList([...contactList]);
    } else {
      setContactItemList([newContactDetail]);
    }
    setSelectedContact(0);
  }, [contactList]);

  useEffect(() => {
    let contact = contactItemList[selectedContact];
    if (contact) {
      setContactName(contact.name);
      setContactPhone(contact.phone);
      setContactEmail(contact.email);
    }
    setInvalidContact && validateMandatory(contactItemList);
  }, [contactItemList, selectedContact]);

  const tab = (index) => {
    let display = contactItemList[index] ? "display-tab" : "";
    let addIconDisplay = !display && contactItemList[index - 1];
    return (
      <div className="tab-selector-ctnr">
        {addIconDisplay && (
          <div className="add-contact-ctnr">
            <i
              className={`fa fa-plus-circle tab-icon`}
              title="Add venue contact"
              onClick={() => addNewContact()}
            />
          </div>
        )}
        <div
          key={`contact${index}`}
          className={`tab-selector ${display} ${
            selectedContact == index ? "selected" : ""
          }`}
        >
          <div
            className="header-name"
            onClick={() => {
              setSelectedContact(index);
            }}
          >
            VENUE CONTACT {index + 1}
          </div>
          <div className="trash-icon-ctnr">
            <Trash_icon
              alt="trash-icon"
              title="Delete venue contact"
              onClick={() => {
                contactItemList.length > 1
                  ? deleteContact(index)
                  : clearInputValues();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="venue-contact-form">
        <div className="contact-tab-container">
          {tab(0)}
          {tab(1)}
          {tab(2)}
        </div>
        <div
          className="detail-container"
          style={
            contactItemList.length == 3 ? { borderTopRightRadius: "0px" } : {}
          }
        >
          <label>
            <span className="venue-label-star">* </span>Venue Contact Name
          </label>
          <DebounceInput
            data-testid="studiocontact-name"
            element={CustomInput}
            overrideStyle={true}
            debounceTimeout={1500}
            autoSize={{ minRows: 1, maxRows: 2 }}
            className={
              "full-width liveshot-notes venue-contact-form-name input transform-input"
            }
            name="studiotruck name"
            autoComplete="off"
            value={contactName || ""}
            placeholder="ENTER NAME"
            onChange={(e) => {
              udpateContactName(e.target.value);
            }}
            maxLength={Constants.INPUT_MAX_LENGTH}
          />
          <label className="padding-top">
            <span className="venue-label-star">* </span>Venue Contact Phone
          </label>
          <DebounceInput
            data-testid="studiocontact-phone"
            element={CustomInput}
            overrideStyle={true}
            debounceTimeout={1500}
            autoSize={{ minRows: 1, maxRows: 2 }}
            className={
              "full-width liveshot-notes venue-contact-form-phone input transform-input" +
              " Studiophone-input transform-input"
            }
            name="studio/truck phone"
            autoComplete="off"
            value={contactPhone || ""}
            placeholder="ENTER PHONE"
            onChange={(e) => {
              updateContactPhone(e.target.value);
            }}
            maxLength={1000}
          />
          <label className="padding-top">Venue Contact Email</label>
          <DebounceInput
            element={CustomInput}
            overrideStyle={true}
            debounceTimeout={1500}
            autoSize={{ minRows: 1, maxRows: 2 }}
            className={
              "full-width liveshot-notes venue-contact-form-name input Studioemail-input transform-input"
            }
            name="studio/truck email"
            autoComplete="off"
            value={contactEmail || ""}
            placeholder="ENTER EMAIL"
            onChange={(e) => {
              updateContactEmail(e.target.value);
            }}
            maxLength={Constants.INPUT_MAX_LENGTH}
          />
          <div className="detail-footer">
            <input
              className="venue-cancel-icon"
              type="button"
              value="CLEAR"
              onClick={clearInputValues}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default VenueContactForm;
